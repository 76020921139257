/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

import React from 'react';
import Image from 'next/image';
import { useLanguage } from '@/providers/LanguageProvider';
import SmileyXEyes from 'public/shared-icons/SmileyXEyes.svg';

import { CustomButton } from '@/components/common';

import SdkContent from './SdkContent';

const NoWorkflowFound = ({
  setOpen,
  fetchAllComponents,
}: {
  setOpen?: (open: boolean) => void;
  fetchAllComponents: any;
}) => {
  const { messages } = useLanguage();
  const langData = messages?.ApplicationForm;
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Image src={SmileyXEyes} alt="smiley X eyes" />
      <p className="font-medium">
        {langData?.unable_to_discover_any_workflows_components}
      </p>
      <p className="text-sm text-form-input-disabled">
        {langData?.this_may_be_due_to_the_absence}
      </p>
      <div className="mt-6 flex gap-4">
        <div className="w-80 rounded-md border border-line-secondary bg-canvas-background-secondary py-6">
          <SdkContent
            setOpen={setOpen}
            fetchAllComponents={fetchAllComponents}
          />
        </div>

        <div className="flex w-80 flex-col justify-between rounded-md border border-line-secondary bg-canvas-background-secondary p-6">
          <div>
            <h3 className="mb-6 text-center text-lg">
              {langData?.define_your}{' '}
              <span className="block">{langData?.own_component}</span>
            </h3>
            <p className="text-center text-sm text-form-input-disabled">
              {langData?.alternately_you_can_manually_define}
            </p>
          </div>
          <CustomButton customSize="regular" disabled customVariant="secondary">
            <span className="mr-2">{langData?.define_component}</span>
            <span className="-ml-0.5 rounded bg-[#EFDA1F] px-1.5 text-btn-label-sm text-[#07181E]">
              {messages?.General?.wip}
            </span>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default NoWorkflowFound;
