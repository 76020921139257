/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import React from 'react';
import Link from 'next/link';
import { useLanguage } from '@/providers/LanguageProvider';

import { CustomButton } from '@/components/common';

const SdkContent = ({
  setOpen,
  fetchAllComponents,
  closePopover,
}: {
  setOpen?: (open: boolean) => void;
  fetchAllComponents?: any;
  closePopover?: boolean;
}) => {
  const { messages } = useLanguage();
  const langData = messages?.ApplicationForm;
  return (
    <div className="w-full">
      <h3 className="mb-2 text-center text-lg">
        {langData?.how_to_deploy_the} <p>{langData?.okahu_sdk_in_your_app}?</p>
      </h3>
      <div className="ml-4 flex gap-4 pt-4">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-line-secondary font-medium text-fill-button-rest">
          1
        </div>
        <div className="w-[221px] pb-2 pr-4">
          <h5 className="text-sm">{langData?.download_the_sdk}</h5>
          <p className="text-[11px] text-form-input-disabled">
            <Link
              href="https://okahu.jfrog.io/artifactory/api/pypi/okahu-pypi/simple"
              className="cursor-pointer text-link-active"
              target="_blank"
              rel="noopener noreferrer"
            >
              {langData?.click_here_to_download}
            </Link>{' '}
            {langData?.the_latest_version_of_the_okahu_sdk}
          </p>
        </div>
      </div>

      <div className="ml-4 flex gap-4 pt-4">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-line-secondary font-medium text-fill-button-rest">
          2
        </div>
        <div className="w-[221px] pr-4">
          <h5 className="text-sm">{langData?.configure_deploy}</h5>
          <p className="text-[11px] text-form-input-disabled">
            {langData?.please_follow_the_instructions}{' '}
            <Link
              className="text-link-active"
              href="https://www.okahu.ai"
              target="_blank"
            >
              {langData?.here}
            </Link>{' '}
            {langData?.to_configure_your_application}
          </p>
        </div>
      </div>

      <div className="ml-4 flex gap-4 pt-4">
        <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-line-secondary font-medium text-fill-button-rest">
          3
        </div>
        <div className="w-[221px] pr-4">
          <h5 className="text-sm">{langData?.restart_and_run}</h5>
          <p className="text-[11px] text-form-input-disabled">
            {langData?.please_restart_your_application}
          </p>
        </div>
      </div>

      {setOpen ? (
        <div className="mx-6 mt-6 flex justify-center gap-3">
          <CustomButton
            customVariant="primary"
            className="h-auto min-w-[130px] text-wrap py-1"
            onClick={() => {
              fetchAllComponents();
              closePopover && setOpen(false);
            }}
          >
            {langData?.retry_discovery}
          </CustomButton>
          <CustomButton
            customVariant="secondary"
            className="h-auto min-w-[130px] text-wrap py-1"
            onClick={() => setOpen(false)}
          >
            {langData?.discover_later}
          </CustomButton>
        </div>
      ) : (
        <div className="mt-6 flex justify-center gap-3 px-6">
          <CustomButton
            customVariant="secondary"
            customSize="regular"
            className="w-full"
          >
            {langData?.rediscover}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default SdkContent;
